// Import all of Bootstrap's JS
import Choices from 'choices.js';

document.addEventListener('DOMContentLoaded', () => {
    // cosmetic events
    addTabClassToBody();
    attachAnimateCheckboxEvent(document);

    let currentPageBg = document.querySelector('[data-is-current-page="true"]');
    let pageBackground = document.querySelector('#page-bg');
    let activeTab = document.querySelector('.fui-tab-active a');

    // read background image from current page background
    setPageBackground(pageBackground, currentPageBg);

    // enable navigation for all tabs that the user has already visited
    enablePreviousTabs(parseInt(activeTab?.dataset?.fuiPageIndex, 10));

    let formieForm = document.querySelector('form[id^="fui-mainBriefingForm-"]');
    formieForm?.addEventListener('onFormiePageToggle', (event) => {
        let data = event.detail.data;
        let nextPageElement = document.querySelector(`[data-page-id="${data.nextPageId}"]`);
        event.preventDefault();

        window.requestAnimationFrame(() => {
            setPageBackground(document.querySelector('#page-bg'), nextPageElement);
            addTabClassToBody(data.nextPageIndex);
            enablePreviousTabs(data.nextPageIndex);
        });
    });
    formieForm?.addEventListener('onAfterFormieEvaluateConditions', (event) => {
        if (event.target?.id.startsWith('fui-tab')) {
            registerTabAnimation(event.target);
        }
        registerChoices(formieForm);
        attachAnimateCheckboxEvent(formieForm);
       
    });
    formieForm?.addEventListener('onAfterFormieSubmit', (event) => {
        if (event.detail.success && event.detail.hasOwnProperty("nextPageId") && event.detail.nextPageId === null) {
            cleanupSpgrHandler();
        }
    });
    let repeaterFields = formieForm?.querySelectorAll('[data-field-type="repeater"]');
    repeaterFields?.forEach(field => {
        ['init', 'append'].forEach((event) => {
            field.addEventListener(event, (e) => {
                let repeaterField = e.detail.repeater;
                registerChoices(repeaterField.$field);
                attachAnimateCheckboxEvent(repeaterField.$field);
                // products handler
                if (field.dataset?.fieldHandle === 'products') {
                    let row = e.detail.row;
                    if (!row) {
                        initSpgrHandler(e.detail.repeater.$field);
                    } else {
                        attachSpgrHandler(row);
                    }
                }
                if (field.dataset?.fieldHandle === 'photosRepeater' || field.dataset?.fieldHandle === 'videosRepeater') {
                    populateSelects(repeaterField.$field);
                }
            });
        });
        field.addEventListener('remove', (e) => {
            let row = e.detail.row;
            handleRemoveProduct(row);
        });
    });

    const withModelsInput = document.querySelector('input[id$="kindOfContent-with-models"]');
    toggleWithModelsClass(withModelsInput);
    withModelsInput?.addEventListener('change', (evt) => {
        toggleWithModelsClass(evt.target);
    })
});

// choices.js - register choices for a select elements, only if they haven't been initialized yet
const registerChoices = function (parent) {
    const fuiSelect = parent.querySelectorAll('.fui-select');
    fuiSelect.forEach(element => {
        if (!(element.id in initializedElements)) {
            initializedElements[element.id] = new Choices(element, {
                allowHTML: false,
                removeItemButton: true,
                placeholderValue: 'Select option'
            });
        }
    });
};

// set the background CSS styles on a given element, then destroy it and re-insert it into the DOM
const setPageBackground = function (bgElement, sourceElement) {
    if (!bgElement || !sourceElement) {
        return;
    }
    let clone = bgElement.cloneNode(true);
    clone.style.backgroundRepeat = 'no-repeat';
    clone.style.backgroundPosition = 'center';
    clone.style.backgroundSize = 'cover';
    clone.style.backgroundImage = `url('${sourceElement.dataset.bgImg}')`;
    let nextEl = bgElement.nextElementSibling;
    let parent = bgElement.parentNode;
    if (nextEl && parent) {
        parent.removeChild(bgElement);
        parent.insertBefore(clone, nextEl);
        clone.classList.add('animated', 'fadeIn');
    }
};

// helper to attach the animation events to checkboxes
const attachAnimateCheckboxEvent = function (parent) {
    const checkboxes = parent.querySelectorAll('.fui-checkbox-input');
    checkboxes.forEach(checkbox => checkbox.addEventListener('change', animateCheckbox, false));
}



// register animation classes on checkboxes based on their checked state
const animateCheckbox = function () {
    if (this.checked) {
        this.parentNode.classList.add('animated', 'tada');
    } else {
        this.parentNode.classList.remove('animated', 'tada');
    }
}

// register animation classes on tabs that don't already have them
const registerTabAnimation = function (tab) {
    if (!tab.conditionallyHidden && !(tab.classList.contains('animated'))) {
        tab.classList.add('animated', 'tada');
    } else if (tab.conditionallyHidden) {
        tab.classList.remove('animated', 'tada');
    }
};

// save SPGR numbers with form model change
const savedSpgrs = {};

// if we are coming from an init event, we need to manually fetch the pre-populated rows from the form
const initSpgrHandler = function (parent) {
    const rows = parent.querySelectorAll('[data-repeater-row]');
    rows.forEach(row => {
        attachSpgrHandler(row);
    });
};

// otherwise, we can attach the handler directly to the row
const attachSpgrHandler = function (parent) {
    const spgrNumber = parent.querySelector('[data-field-handle="spgrNumber"] input');
    const title = parent.querySelector('[data-field-handle="title1"] input');
    // write the product to memory if SPGR number exists and is not saved already
    if (spgrNumber && spgrNumber.value) {
        const product = createProduct(parent);
        if (!(product?._id in savedSpgrs)) {
            savedSpgrs[product._id] = product;
            afterUpdateSpgrHandler();
        }
    }
    [spgrNumber, title].forEach(field => {
        field && field.addEventListener('change', (event) => {
            handleProductChange(parent);
        });
    });
};

const createProduct = function (parent) {
    const spgrNumber = parent.querySelector('[data-field-handle="spgrNumber"] input');
    const title = parent.querySelector('[data-field-handle="title1"] input');
    return {
        spgrNumber: spgrNumber?.value,
        title: title?.value,
        _id: spgrNumber?.id
    };
};

// only write to memory if SPGR number exists
const handleProductChange = function (parent) {
    const product = createProduct(parent);
    if (!product.spgrNumber) {
        return;
    }
    savedSpgrs[product._id] = product;
    afterUpdateSpgrHandler();
};

// if a row with existing SPGR number is removed, also remove it from memory and then write to local storage
const handleRemoveProduct = function (parent) {
    const product = createProduct(parent);
    if (product.spgrNumber && product._id in savedSpgrs) {
        delete savedSpgrs[product._id];
        afterUpdateSpgrHandler();
    }
};

const populateSelects = function (parent) {
    const selects = parent.querySelectorAll('.fui-select');
    const storedSpgrs = window.sessionStorage.getItem('savedSpgrs');
    if (!storedSpgrs) {
        return;
    }
    const parsedSpgrs = JSON.parse(storedSpgrs);
    const options = createOptions(parsedSpgrs);
    selects.forEach(select => {
        if (select.id in initializedElements && select.classList.contains('custom-spgr-select')) {
            const choice = initializedElements[select.id];
            const selectedOptions = choice.getValue();
            const optionsInSpgrs = selectedOptions.filter((option) => option.customProperties?.id in parsedSpgrs);
            const allProductsSelected = selectedOptions.some((option) => option.value === "All Products");
            choice.clearChoices().removeActiveItems().setChoices(options, 'value', 'label', true);
            if (allProductsSelected) {
                choice.setChoiceByValue("All Products");
            }
            if (optionsInSpgrs.length) {
                choice.setChoiceByValue(optionsInSpgrs.map((option) => parsedSpgrs[option.customProperties.id].spgrNumber));
            }
            // manually set the value of a hidden input field to the selected values
            choice.passedElement.element.addEventListener('addItem', (evt) => {
                const srcElement = evt.srcElement;
                const value = evt.detail.value;
                const input = document.querySelector(`input[name="${srcElement.dataset?.inputName}"]`);
                if (input) {
                    if (input.value === "") {
                        input.value = value;
                    } else {
                        const selectedValues = input.value.split(', ');
                        if (!selectedValues.includes(value)) {
                            input.value = [value].concat(selectedValues).join(', ');
                        }
                    }
                }
            });
            // handle manual removal
            choice.passedElement.element.addEventListener('removeItem', (evt) => {
                const srcElement = evt.srcElement;
                const value = evt.detail.value;
                const input = document.querySelector(`input[name="${srcElement.dataset?.inputName}"]`);
                if (input) {
                    const selectedValues = input.value.split(', ');
                    input.value = selectedValues.filter((val) => val !== value).join(', ');
                }
            });
        }
    });
};

const createOptions = function(spgrs) {
    const allProductsOption = {
        value: "All Products",
        label: "All Products",
    };
    return [allProductsOption].concat(Object.keys(spgrs).map(id => {
        return {
            value: spgrs[id].spgrNumber,
            label: spgrs[id].spgrNumber,
            customProperties: {
                id
            }
        };
    }));
}

const writeSpgrToSessionStorage = function () {
    window.sessionStorage.setItem('savedSpgrs', JSON.stringify(savedSpgrs));
    const dropdowns = document.querySelectorAll('[data-field-handle="spgrNumberDropdown"]');
    dropdowns.forEach(dropdownElement => {
        populateSelects(dropdownElement);
    });
};

const removeSpgrFromSessionStorage = function () {
    window.sessionStorage.removeItem('savedSpgrs');
};

const afterUpdateSpgrHandler = function () {
    writeSpgrToSessionStorage();
    for (const [id, choice] of Object.entries(initializedElements)) {
        const passedEl = choice.passedElement.element;
        if (passedEl.classList.contains('custom-spgr-select')) {
            populateSelects(passedEl.parentNode);
        }
    }
};

const cleanupSpgrHandler = function () {
    removeSpgrFromSessionStorage();
};

const addTabClassToBody = function (nextPageIndex) {
    // remove previous tab- classes
    const bodyClasses = document.body.classList;
    bodyClasses.forEach((className) => {
        if (className.startsWith('tab-')) {
            bodyClasses.remove(className);
        }
    });
    // if specified from event we don't need to look for the active tab
    if (nextPageIndex) {
        bodyClasses.add(`tab-${nextPageIndex}`);
    } else {
        const activeTab = document.querySelector('.fui-tab-active');
        if (activeTab) {
            // set from active tab anchor's dataset
            bodyClasses.add('tab-' + activeTab.querySelector('a[data-fui-page-index]')?.dataset?.fuiPageIndex);
        }
    }
};

const toggleWithModelsClass = function (target) {
    if (target?.checked) {
        document.body.classList.add("with-models-selected");
    } else {
        document.body.classList.remove("with-models-selected");
    }
};

const enablePreviousTabs = function (nextPageIndex) {
    const tabs = document.querySelectorAll('.fui-tabs .fui-tab:not(.logout-button)');
    tabs.forEach((tab) => {
        const tabLink = tab.querySelector('a[data-fui-page-index]');
        const tabLinkIndex = parseInt(tabLink.dataset?.fuiPageIndex, 10);
        if (tabLinkIndex <= nextPageIndex) {
            tab.removeAttribute('data-disabled');
        }
    });
};

// choices.js - save already initialized elements
const initializedElements = {};